#skills {
    background-image: linear-gradient(135deg, $primary-color 0%, $secondary-color 100%);
    -webkit-clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
    padding: 15rem 0 10rem 0;
    margin-top: -rem;
    margin-bottom: -1px;
    color: $white-color;
    display: flex;
    // flex-direction: row;
    justify-content: space-around;
  
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      padding: 10rem 0;
    }
  
    .contact-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 3.2rem;
      padding: 0 2rem;
      backface-visibility: hidden;
      
  
      &__text {
        text-align: center;
      }
  
      .skills-section {
        // display: flex;
        // flex-direction: column;
        align-items: center;
        margin: 30px;
        text-align: center;
      }
  
      .icon {
        height: 50px;
        width: 50px;
        margin-top: 7px;
      }

      .hidden {
        opacity: 0;
      }
  
      &__text,
      a {
        font-size: 2rem;
  
        @media screen and (min-width: 768px) {
          font-size: 2.4rem;
        }
      }
    }
  }
  

// #skills {
//     background-image: linear-gradient(135deg,
//       $primary-color 0%,
//       $secondary-color 100%
//     );
//     -webkit-clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
//     clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
//     padding: 15rem 0 10rem 0;
//     margin-top: -rem;
//     margin-bottom: -1px;
//     color: $white-color;
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
  
//     @media screen and (max-width: 767px) {
//       padding: 10rem 0;
//     }
  
//     .contact-wrapper {
//       display: flex;
//       justify-content: space-between;
//       margin-top: 3.2rem;
//       padding: 0 2rem;
//       backface-visibility: hidden;
  
//       &__text {
//         display: flex;
//         margin-bottom: 2.5rem;
//       }

//       .skills-section {
//         // display: flex;
//         // justify-content: space-between;
//         margin: 30px;
//         text-align: center;
//       }
  
//       .icon {
//         height: 50px;
//         width: 50px;
//         padding-right: 10px;
//       }
  
//       &__text,
//       a {
//         font-size: 2rem;
  
//         @media screen and (min-width: 768px) {
//           font-size: 2.4rem;
//         }
//       }
//     }
//   }
$content-width: 95%;
$breakpoint: 798px;
$nav-height: 20px;
$nav-background: #fff;
$nav-font-color: #0c0c0c;
$link-hover-color: $primary-color;

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

body{
    font-family: "Montserrat", sans-serif;
}

.navigation {
  height: $nav-height;
  background: $nav-background;
}

.nav-container {
  max-width: $content-width;
  margin: 0 auto;
}


nav {
  float: right;
  ul {
      list-style: none;
      margin: 0;
      padding: 0;
    li {
      float: left;
      position: relative;
      a {
        display: block;
        padding: 0px 0px 0 20px;
        line-height: 50px;
        background: $nav-background;
        color: $nav-font-color;
        text-decoration: none;
        
        
         &::before {
           transition: 300ms;
           height: 5px;
           content: "";
           position: absolute;
           background-color: $link-hover-color;
           width: 0%;
           bottom: 0px;
        }

        &:hover::before{
          width: 70%;
        }
        &:not(:only-child):after {
          padding-left: 4px;
          content: ' ▾';
        }
      } // Dropdown list
      ul li {
        min-width: 190px;
        a {
          padding: 15px;
          line-height: 20px;
        }
      }
    }
  }
}


.nav-dropdown {
  display: none;
  position: absolute;
  z-index: 1;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
}

.nav-mobile {
  display: none;
  // position: absolute;
  top: 10;
  // right: 50;
//   background: $nav-background;
  height: $nav-height;
  width: $nav-height;
}

@media only screen and (max-width: $breakpoint) {
  .nav-mobile {
    display: block;
  }
  nav {
      width: 100%;
      padding: $nav-height 0 15px;
    ul {
      display: none;
      
      li{
        float: none;
        
        a {
          padding: 15px;
          line-height: 20px;
          
          &:hover::before{
            width: 20%;
          }
        }
        ul li a {
          padding-left: 30px;
        }
      }
    }
  }
  .nav-dropdown {
    position: static;
  }
}

#nav-toggle {
  position: absolute;
  left: 18px;
  top: 22px;
  cursor: pointer;
  padding: 10px 35px 16px 0px;
  span,
  span:before,
  span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 5px;
    width: 35px;
    background: $link-hover-color;
    position: absolute;
    display: block;
    content: '';
    transition: all 300ms ease-in-out;
  }
  span:before {
    top: -10px;
  }
  span:after {
    bottom: -10px;
  }
  &.active span {
    background-color: transparent;
    &:before,
    &:after {
      top: 0;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

@media screen and (min-width: $breakpoint) {
  .nav-list {
    display: block !important;
  }
}

.hamburger1 {
  height: 45px;
  margin: 10px;
  display: -ms-grid;
  // display: grid;
  grid-template-rows: repeat(3, 1fr);
  // justify-items: center;
  z-index: 120;
}

.hamburger1 div {
  background-color: $link-hover-color;
  // position: relative;
  width: 40px;
  height: 5px;
  margin-top: 7px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

#toggle1 {
  display: none;
}

#toggle1:checked + .hamburger1 .top {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin-top: 22.5px;
}

#toggle1:checked + .hamburger1 .meat {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  margin-top: -5px;
}

#toggle1:checked + .hamburger1 .bottom {
  -webkit-transform: scale(0);
          transform: scale(0);
}

#toggle1:checked ~ .menu1 {
  height: 340px;
}


/* Menu */
.menu1 {
  width: 100%;
  background-color: transparent;
  color: $secondary-color;
  margin-top: -100px;
  margin-right: -150;
  // margin-bottom: 5px;
  // margin-left: 30;
  display: -ms-grid;
  display: grid;
  grid-template-rows: 1fr repeat(4, 0.5fr);
  grid-row-gap: 0;
  padding: -10;
  list-style: none;
  clear: both;
  width: auto;
  text-align: center;
  height: 0px;
  overflow: hidden;
  transition: height .4s ease;
  z-index: 120;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.menu1 a:first-child {
  margin-top: 40px;
}

.menu1 a:last-child {
  margin-bottom: 40px;
}

.link1 {
  width: 100%;
  margin: 0;
  padding:  0;
  color: $secondary-color;
  font: 700 20px 'Montserrat', sans-serif;
}

.link1:hover {
  background-color: #fff;
  color: $primary-color;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}